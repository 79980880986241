<template>
    <div class="flex flex-1 overflow-y-auto flex-col space-y-6 max-h-full max-w-full overflow-x-hidden">
        <div class="grid flex-1 grid-cols-2 space-x-6 overflow-y-auto max-w-full">
            <div class="overflow-y-auto bg-card-bg p-4 rounded-md max-w-full" style="min-height: 300px">
                <div v-if="loadingStates && loadingStates.teams" class="flex justify-center items-center min-h-full">
                    <Loader />
                </div>
                <ListView
                    v-else
                    :disableCheckbox="assigningWorkAllocation.team || !disableGroups"
                    :disableToggle="assigningWorkAllocation.team || !disableGroups"
                    title="Groups that have default access to the case"
                    :listItems="filteredTeams"
                    showSearch
                    selectKey="has_access"
                    class="h-full overflow-y-auto"
                    toggleKey="assigned"
                    searchRowLabel="Assign"
                    @rowClick="selectTeamDefaultAccess($event)"
                    @handleFilterResult="handleFilterResult($event, 'teams')"
                />
            </div>
            <div class="overflow-y-auto bg-card-bg p-4 rounded-md max-w-full" style="min-height: 300px">
                <div v-if="loadingStates && loadingStates.users" class="flex justify-center items-center min-h-full">
                    <Loader />
                </div>
                <ListView
                    v-else
                    :disableCheckbox="assigningWorkAllocation.user || !disableUsers"
                    :disableToggle="assigningWorkAllocation.user || !disableUsers"
                    title="Users that have default access to the case"
                    :listItems="filteredUsers"
                    showSearch
                    class="h-full overflow-y-auto"
                    valueKey="user_name"
                    selectKey="has_access"
                    toggleKey="assigned"
                    searchRowLabel="Assign"
                    @rowClick="selectUserDefaultAccess($event)"
                    @handleFilterResult="handleFilterResult($event, 'users')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ListView from "@shared/components/lists";
import axios from "@/axios";
import Loader from "@shared/loader";
import { checkPermission } from "@shared/utils/functions"
import { PermissionError } from "@shared/utils/permissions";

export default {
    name: "client-work-allocation",

    components: {
        ListView,
        Loader,
    },

    data() {
        return {
            loadingStates: {
                teams: false,
                users: false,
            },
            searchValues:{
                teams: "",
                users: "",
            },
            workAllocationData: {
                users: [],
                teams: [],
            },
            assigningWorkAllocation: {
                team: false,
                user: false,
            },
        };
    },

    computed:{
        filteredTeams(){
            return this.teams?.filter(el => (el.name?.toLowerCase()?.includes(this.searchValues.teams.toLowerCase())));
        },
        filteredUsers(){
            return this.users?.filter(el => (el.user_name?.toLowerCase()?.includes(this.searchValues.users.toLowerCase())));
        },
        teams() {
            return [...this.workAllocationData.teams].sort((a, b) => b.has_access - a.has_access);
        },
        users() {
            return [...this.workAllocationData.users].sort((a, b) => b.has_access - a.has_access);
        },
        disableUsers() {
            return this.checkPermission("client.edit") && this.checkPermission("users.read");
        },
        disableGroups() {
            return this.checkPermission("client.edit") && this.checkPermission("groups.read");
        },
        clientId() {
            return this.$route.params.clientId;
        },
    },
    async mounted() {
        this.loadingStates.teams = true;
        this.loadingStates.users = true;
        this.workAllocationData = await this.fetchWorkAllocationList();
    },

    methods: {
        checkPermission,

        handleFilterResult(event, type){
            this.searchValues[type] = event
        },
        async fetchWorkAllocationList() {
            const [userData, teamData] = await Promise.all([this.fetchUsersList(), this.fetchTeamsList()]);

            return {
                users: userData ?? [],
                teams: teamData ?? [],
            };
        },
        async fetchUsersList() {
            this.loadingStates.users = true;
            try {
                const { data } = await axios.get(`work-allocation/users/tenant?client_id=${this.clientId}`);
                return data;
            } catch (error) {
                console.log(error, "Error");
            } finally {
                this.loadingStates.users = false;
            }
        },
        async fetchTeamsList() {
            this.loadingStates.teams = true;
            try {
                const { data } = await axios.get(`work-allocation/groups/tenant?client_id=${this.clientId}`);
                return data;
            } catch (error) {
                console.error(error, "Error");
            } finally {
                this.loadingStates.teams = false;
            }
        },

        async updateTeamAllocation(team) {
            const { id, has_access, assigned } = team;
            const url = `work-allocation/client/${this.clientId}/group/${id}/tenant`;
            try {
                const { data } = await axios.put(url, { has_access, assigned });
                this.$toast.success(data.message || "team assigned to client");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to assign team to client");
            }
        },

        async updateUserAllocation(user) {
            const { id, has_access, assigned } = user;
            const url = `work-allocation/client/${this.clientId}/user/${id}/tenant`;
            try {
                const { data } = await axios.put(url, { has_access, assigned });
                this.$toast.success(data.message || "user assigned to client");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to assign user to client");
            }
        },

        async selectTeamDefaultAccess({ row, value, toggle }) {
            this.assigningWorkAllocation.team = true;
            try {
                row.has_access = value;
                this.handleAssignedChange({ row, toggle });
                const { id, assigned } = row;
                await this.updateTeamAllocation({ id, has_access: value, assigned });
            } catch (error) {
                console.error("Error", error);
            }
            this.assigningWorkAllocation.team = false;
        },

        async selectUserDefaultAccess({ row, value, toggle }) {
            this.assigningWorkAllocation.user = true;
            try {
                row.has_access = value;
                this.handleAssignedChange({ row, toggle });
                const { id, assigned } = row;
                await this.updateUserAllocation({ id, has_access: value, assigned });
            } catch (error) {
                console.error("Error", error);
            }
            this.assigningWorkAllocation.user = false;
        },

        handleAssignedChange({ row, toggle }) {
            const { assigned } = row;
            const isAssignedChanged = assigned !== toggle;
            if (!isAssignedChanged) return;

            const permission = "case.edit";
            const isPermitted = checkPermission(permission);
            if (!isPermitted) {
                const permissionError = new PermissionError(permission);
                this.$toast.error(permissionError.message);
                throw permissionError;
            } else {
                row.assigned = toggle;
            }
        },
    },
};
</script>
