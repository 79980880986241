import clientInformation from './clientInformation'
import contact from './contact'
import address from './address'
import financial_details from './financial_details'
import configuration from './configuration'
import packageandcheck from "./PackageAndCheck"
import workAllocation from "./work-allocation"
import groups from "../components/groups.vue"
import users from "../components/users.vue"
import rolesAndPermissions from "./rolesAndPermissions.vue"

export default {
    client_information: clientInformation,
    contact,
    address,
    financial_details,
    configuration,
    packageandcheck,
    work_allocation: workAllocation,
    groups,
    users,
    rolesAndPermissions,
}